import React, { Fragment } from 'react';
import { AiFillYoutube, AiOutlineInstagram } from 'react-icons/ai';
import { BiLogoFacebookSquare } from 'react-icons/bi';
import { BsPinterest, BsTwitter } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import FooterLogo from '../assets/images/logo.svg';
import NewLetters from './NewLetters';
import '../assets/scss/components/footer/footer.scss';

const Footer = () => {
  return (
    <Fragment>
      <div className="footer_new_letters">
        <NewLetters />
      </div>
      <div className="footer_safe_banner">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="26"
          viewBox="0 0 22 26"
          fill="none"
        >
          <path
            d="M21.5586 4.9428C21.4612 4.83162 21.342 4.74173 21.2083 4.67875C21.0746 4.61577 20.9293 4.58107 20.7816 4.5768C18.4866 4.5168 15.5826 2.0628 13.6626 1.0998C12.4766 0.506801 11.6936 0.115801 11.1056 0.0128014C10.9862 -0.004596 10.8649 -0.00425902 10.7456 0.0138014C10.1576 0.116801 9.3746 0.507801 8.1896 1.1008C6.2696 2.0628 3.3656 4.5168 1.0706 4.5768C0.922774 4.58129 0.777441 4.61609 0.643613 4.67904C0.509784 4.742 0.390313 4.83178 0.292604 4.9428C0.0900769 5.17194 -0.0144167 5.47141 0.00160396 5.7768C0.494604 15.7998 4.0896 22.0028 10.3976 25.6078C10.5616 25.7008 10.7436 25.7488 10.9246 25.7488C11.1056 25.7488 11.2876 25.7008 11.4526 25.6078C17.7606 22.0028 21.3546 15.7998 21.8486 5.7768C21.8656 5.47146 21.7613 5.17176 21.5586 4.9428ZM16.5426 8.8848L11.2196 16.7398C11.0286 17.0218 10.7286 17.2088 10.4316 17.2088C10.1336 17.2088 9.8026 17.0458 9.5936 16.8368L5.8416 13.0838C5.71911 12.9608 5.65033 12.7944 5.65033 12.6208C5.65033 12.4472 5.71911 12.2808 5.8416 12.1578L6.7686 11.2288C6.89181 11.1068 7.0582 11.0383 7.2316 11.0383C7.405 11.0383 7.57139 11.1068 7.6946 11.2288L10.1346 13.6688L14.3736 7.4118C14.4717 7.26862 14.6224 7.17006 14.7929 7.13765C14.9634 7.10523 15.1398 7.1416 15.2836 7.2388L16.3686 7.9748C16.5119 8.07273 16.6107 8.2234 16.6433 8.39391C16.6759 8.56442 16.6397 8.7409 16.5426 8.8848Z"
            fill="#07A329"
          />
        </svg>
        100% sécurisé.
      </div>
      <div className="footer_links">
        <div>
          <div className="footer_links__left">
            <img src={FooterLogo} alt="" />
          </div>
          <div className="footer_links__right">
            <div className="footer_links__right__group">
              <div
                className="footer_links__right__group__title"
                style={{ color: 'black' }}
              >
                Shop
              </div>
              <div className="footer_links__right__group__links">
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Gift cards
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Digifoire Registry
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Sitemap
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Digifoire blog
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Digifoire United Kingdom
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Digifoire Germany
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Digifoire Canada
                </Link>
              </div>
            </div>
            <div className="footer_links__right__group">
              <div className="footer_links__right__group__title">Vente</div>
              <div className="footer_links__right__group__links">
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Vendre a la foire
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Digifoire Registry
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Teams
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Forums
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Affiliates & Creators
                </Link>
              </div>
            </div>
            <div className="footer_links__right__group">
              <div className="footer_links__right__group__title">A propos</div>
              <div className="footer_links__right__group__links">
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  De la foire
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Policies
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Sitemap
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Investors
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Careers
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Press
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Impact
                </Link>
              </div>
            </div>
            <div className="footer_links__right__group">
              <div className="footer_links__right__group__title">Aide</div>
              <div className="footer_links__right__group__links">
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Help Center
                </Link>
                <Link
                  className="footer_links__right__group__links__link"
                  style={{ color: 'black' }}
                  to="/"
                >
                  Do not sell or share my personal info
                </Link>
                <Link
                  className="footer_links__right__group__links__email"
                  to="/"
                >
                  <MdOutlineEmail size={24} /> kati360@gmail.com
                </Link>
                <div className="footer_links__right__group__links__reseaux">
                  <Link to="/">
                    <AiOutlineInstagram size={24} />
                  </Link>
                  <Link to="/">
                    <BiLogoFacebookSquare size={24} />
                  </Link>
                  <Link to="/">
                    <BsPinterest size={24} />
                  </Link>
                  <Link to="/">
                    <BsTwitter size={24} />
                  </Link>
                  <Link to="/">
                    <AiFillYoutube size={24} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <div>
          <div className="footer_bottom__left">
            <div>Dakar  |   Français   |   (XOF)</div>
          </div>
          <div className="footer_bottom__right">
            <div className="footer_bottom__right__link">
              © 2023 Kati360, Inc.
            </div>
            <Link className="footer_bottom__right__link" to="/">
              Terms of Use
            </Link>
            <Link className="footer_bottom__right__link" to="/">
              Privacy
            </Link>
            <Link className="footer_bottom__right__link" to="/">
              Interest-based ads
            </Link>
            <Link className="footer_bottom__right__link" to="/">
              Local Shops
            </Link>
            <Link className="footer_bottom__right__link" to="/">
              Regions
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
