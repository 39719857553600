import React from 'react';

const Annonces = () => {
  return (
    <div className="lg:mt-10">
      <section
        className=" annonceImage w-full bg-center bg-no-repeat bg-cover bg-gray-500 bg-blend-multiply"
      >
        <div className="w-full mx-auto max-w-screen-xl text-center py-20 px-4 sm:px-6 lg:px-8">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
            MOUKAT Distribution et KATi360
          </h1>
          <p className="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">
            Vous facilitent votre rentrée scolaire avec Digifoire
            <br />
            Tel: (+221) 78 128 63 98
          </p>
        </div>
      </section>
    </div>
  );
};

export default Annonces;
