import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import apiService from '../services/api';
import {
  CLASSES_PAR_CATEGORIES,
  ERROR_RECUP,
  NEW_PATH,
  OLD_PATH,
} from '../constant/_const';
import Loader from './Loader';
import Header from './Header';
import { Card } from 'flowbite-react';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import Swipper from '../components/_Swipper';
const Details = () => {
  const { id } = useParams();
  const [categoryDetails, setCategoryDetails] = useState({ data: [] });
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [loading, setLoading] = useState(true);
  const URL_MEDIA = process.env.REACT_APP_API_URL_MEDIA;

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      setLoading(true);
      try {
        const response = await apiService.get(
          `${CLASSES_PAR_CATEGORIES}?category_id=${id}`,
        );
        setCategoryDetails(response);
      } catch (error) {
        console.error(ERROR_RECUP, error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryDetails();
  }, [id]);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const schools = categoryDetails.data.flatMap((classe) =>
    classe.school_classes.map((sc) => sc.school),
  );
  const classes = categoryDetails.data.map((classe) => classe.name);

  const groupSuppliesByType = (supplies) => {
    return supplies.reduce((acc, supply) => {
      if (!acc[supply.supply_type]) {
        acc[supply.supply_type] = [];
      }
      acc[supply.supply_type].push(supply);
      return acc;
    }, {});
  };

  const addToDetailsOffers = (classe, schoolClass) => {
    const data = {
      className: classe.name,
      classCategory: classe.class_category,
      school: schoolClass.school,
      supplies: groupSuppliesByType(schoolClass.supplies),
      image: `${URL_MEDIA}/${classe.image.replace(OLD_PATH, NEW_PATH)}`,
    };
    localStorage.setItem('detailsOffers', JSON.stringify(data));
  };

  return (
    <>
      <Header />
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Les offres par classe </h1>
        <div className="lg:w-96 lg:px-5 flex flex-col sm:flex-row justify-start gap-4">
          <div className="w-full sm:w-1/2">
            <label htmlFor="school_select" className="sr-only">
              Choisir école
            </label>
            <select
              id="school_select"
              className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={selectedSchool}
              onChange={(e) => setSelectedSchool(e.target.value)}
            >
              <option value="" disabled>
                Choisir école
              </option>
              {[...new Set(schools)].map((school, index) => (
                <option key={index} value={school}>
                  {school}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full sm:w-1/2">
            <label htmlFor="class_select" className="sr-only">
              Choisir classe
            </label>
            <select
              id="class_select"
              className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              <option value="" disabled>
                Choisir classe
              </option>
              {classes.map((classe, index) => (
                <option key={index} value={classe}>
                  {classe}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Swipper>
          {categoryDetails.data.length > 0 ? (
            <div className="flex flex-row space-x-4 overflow-x-auto scrollbar-hidden">
              {categoryDetails.data
                .filter(
                  (classe) =>
                    (!selectedClass || classe.name === selectedClass) &&
                    (!selectedSchool ||
                      classe.school_classes.some(
                        (schoolClass) => schoolClass.school === selectedSchool,
                      )),
                )
                .map((classe) =>
                  classe.school_classes
                    .filter(
                      (schoolClass) =>
                        schoolClass.supplies.length > 0 && // Vérifie si la classe a des fournitures
                        (!selectedSchool ||
                          schoolClass.school === selectedSchool),
                    )
                    .map((schoolClass, index) => (
                      <div
                        key={`${classe.id}-${schoolClass.school}-${schoolClass.id}-${index}`}
                        className="flex-shrink-0 flex flex-col items-center mb-6 w-full sm:w-80 md:w-96 lg:w-72 xl:w-80 2xl:w-96"
                      >
                        <Card
                          imgAlt={classe.name}
                          imgSrc={`${URL_MEDIA}/${classe.image.replace(
                            OLD_PATH,
                            NEW_PATH,
                          )}`}
                          className="rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                        >
                          <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                            {classe.name}
                          </h5>
                          <h6 className="text-lg font-semibold text-gray-700 dark:text-gray-400 mb-2">
                            {classe.class_category}
                          </h6>
                          <div className="text-sm text-gray-600 dark:text-gray-400 mb-4">
                            <div className="w-full">
                              <h3 className="text-lg font-semibold">
                                Ecole : {schoolClass.school}
                              </h3>
                              {Object.keys(
                                groupSuppliesByType(schoolClass.supplies),
                              ).length > 0 ? (
                                Object.entries(
                                  groupSuppliesByType(schoolClass.supplies),
                                ).map(([type, supplies], typeIndex) => (
                                  <div
                                    key={`${classe.id}-${type}-${schoolClass.id}-${typeIndex}`}
                                    className="mt-4"
                                  >
                                    <h4 className="text-md font-semibold">
                                      {type}
                                    </h4>
                                    <ul className="list-disc mt-2 space-y-2">
                                      {supplies
                                        .slice(0, 5)
                                        .map((supply, supplyIndex) => (
                                          <li
                                            key={`${supply.id}-${type}-${supplyIndex}-${schoolClass.id}`}
                                            className="flex items-center"
                                          >
                                            <FontAwesomeIcon
                                              icon={faSquareCheck}
                                              className="mr-2 text-blue-500"
                                            />
                                            <p>{supply.name}</p>
                                          </li>
                                        ))}
                                      {supplies.length > 5 && <>. . . . .</>}
                                    </ul>
                                  </div>
                                ))
                              ) : (
                                <p className="mt-2 text-center">
                                  Aucune fourniture trouvée pour cette classe.
                                </p>
                              )}
                            </div>
                          </div>

                          <Link
                            to={`/classe/${classe.id}`}
                            className="flex justify-center text-center px-5"
                          >
                            <button
                              onClick={() =>
                                addToDetailsOffers(classe, schoolClass)
                              }
                              className="text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full"
                            >
                              Voir Plus
                            </button>
                          </Link>
                        </Card>
                      </div>
                    )),
                )}
            </div>
          ) : (
            <div className="text-center font-bold text-lg mt-10">
              Aucune classe trouvée pour cette catégorie.
            </div>
          )}
        </Swipper>
      </div>
      <Footer />
    </>
  );
};

export default Details;
