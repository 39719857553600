import React, { useState } from 'react';
import { SEARCH_QUERY } from '../constant/_const';
import apiService from '../services/api';

const Recherche = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    apiService.post(SEARCH_QUERY, { searchQuery, });
  };

  return (
    <>
      <div className="px-4">
        <form
          className="max-w-full lg:max-w-4xl mx-auto mt-5 relative"
          onSubmit={handleSubmit}
        >
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Rechercher ...
          </label>
          <div className="relative flex items-center">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="search_query"
              className="block w-full pl-10 p-4 text-sm text-gray-900 border border-gray-200 rounded-lg bg-gray-50 focus:ring-gray-100 focus:border-gray-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Rechercher..."
              required
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
            <button
              type="submit"
              className="absolute lg:w-auto sm:w-4 right-0 mr-2 lg:mr-4 flex items-center pr-3 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            >
              Rechercher
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Recherche;
