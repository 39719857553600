import React from 'react';
import { Link } from 'react-router-dom';
import Swipper from '../components/_Swipper';
import Skeleton from 'react-loading-skeleton';
import { NEW_PATH, OLD_PATH } from '../constant/_const';
import { useTrackClick } from '../services/tracking';

const Classes = ({ classes }) => {
  const URL_MEDIA = process.env.REACT_APP_API_URL_MEDIA;
  const DEFAULT_IMAGE = '/path/to/default-image.jpg';
  const { trackClick } = useTrackClick();

  if (classes.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-[100px] py-2 space-x-4">
        <Skeleton width={300} height={100} />
        <Skeleton width={300} height={100} />
        <Skeleton width={300} height={100} />
        <Skeleton width={300} height={100} />
      </div>
    );
  }

  return (
    <div className="mt-10 px-4 pb-2 sm:px-6 lg:px-8">
      <span className="text-lg sm:text-2xl lg:text-2xl font-bold mb-4">
        Liste des classes
      </span>

      <Swipper>
        {classes.map((classe) => {
          const imagePath = classe.image
            ? classe.image.replace(OLD_PATH, NEW_PATH)
            : DEFAULT_IMAGE;
          const imageUrl = `${URL_MEDIA}/${imagePath}`;

          return (
            <div
              key={classe.id}
              className="flex-shrink-0 pt-3 lg:pt-0 -ml-9 lg:ml-0 flex flex-col items-center mb-6 mx-2 w-48 sm:w-56 lg:w-64"
            >
              <Link to={`/classes/details/${classe.id}`}
                onClick={() => {
                  trackClick(`A cliqué sur l'école ${classe.name}`, "user_1");
                }}
              >
                <img
                  src={imageUrl}
                  alt={classe.description || 'Classe'}
                  className="rounded-full w-24 h-24 sm:w-32 sm:h-32 lg:w-40 lg:h-40 object-cover"
                />
              </Link>
              <h3 className="mt-4 text-center text-sm sm:text-base lg:text-lg font-semibold text-gray-900">
                {classe.name}
              </h3>
            </div>
          );
        })}
      </Swipper>
    </div>
  );
};

export default Classes;
