import React from 'react';
import { Card } from 'flowbite-react';
import { Link } from 'react-router-dom';
import Swipper from '../components/_Swipper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { NEW_PATH, OLD_PATH } from '../constant/_const';
import Loader from '../common/Loader';
import Skeleton from 'react-loading-skeleton';

const maxSupplies = 5;

const OffreClasses = ({ alloffres }) => {
  const { data } = alloffres || {};
  const URL_MEDIA = process.env.REACT_APP_API_URL_MEDIA;

  if (!data || !Array.isArray(data)) {
    return (
      <div className="mt-10 px-4 sm:px-6 lg:px-8">
        <div className="pb-4 pl-3 font-bold text-lg sm:text-xl">
          Chargement des offres par classe ...
          <div className="flex justify-center items-center min-h-[100px] py-2 space-x-4">
            <Skeleton width={300} height={500} />
            <Skeleton width={300} height={500} />
            <Skeleton width={300} height={500} />
            <Skeleton width={300} height={500} />
          </div>
        </div>
      </div>
    );
  }

  const groupSuppliesByType = (supplies) => {
    return supplies.reduce((acc, supply) => {
      if (!acc[supply.supply_type]) {
        acc[supply.supply_type] = [];
      }
      acc[supply.supply_type].push(supply);
      return acc;
    }, {});
  };

  const addToDetailsOffers = (classe, schoolClass) => {
    const groupedSupplies = groupSuppliesByType(schoolClass.supplies);
    const data = {
      className: classe.name,
      classCategory: classe.class_category,
      school: schoolClass.school,
      supplies: groupedSupplies,
      image: `${URL_MEDIA}/${classe.image.replace(OLD_PATH, NEW_PATH)}`,
    };
    localStorage.setItem('detailsOffers', JSON.stringify(data));

  };

  return (
    <div className="lg:mt-10 py-4">
      <div className="flex justify-between">
        <div className="font-bold text-lg pl-2 sm:text-xl lg:text-2xl">
          Les offres par classe
        </div>
      </div>
      <Swipper>
        {data.map((classe) => {
          const schoolClassesWithSupplies = classe.school_classes.filter(
            (schoolClass) => schoolClass.supplies.length > 0,
          );
          if (schoolClassesWithSupplies.length === 0) {
            return null;
          }
          const imagePath = classe.image.replace(OLD_PATH, NEW_PATH);
          const imageUrl = `${URL_MEDIA}/${imagePath}`;
          return (
            <div
              key={classe.id}
              className="flex-shrink-0 pt-5 lg:pt-0 flex flex-col items-center mx-2 w-64 sm:w-72 lg:w-96"
            >
              <Card imgAlt={`Image de ${classe.name}`} imgSrc={imageUrl}>
                <h5 className="text-lg sm:text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                  Classe : {classe.name}
                </h5>
                <h6 className="text-md sm:text-lg font-semibold text-gray-700 dark:text-gray-400">
                  Ecole :{' '}
                  {classe.school_classes &&
                    classe.school_classes[0] &&
                    classe.school_classes[0].school
                    ? classe.school_classes[0].school
                    : 'École non disponible'}
                </h6>
                <div className="text-xs sm:text-sm text-gray-600 dark:text-gray-400 mb-2">
                  <ul>
                    {Object.entries(
                      groupSuppliesByType(
                        schoolClassesWithSupplies[0].supplies,
                      ),
                    ).map(([supplyType, supplies]) => (
                      <li className="space-y-2" key={supplyType}>
                        <strong>{supplyType}</strong>:
                        <ul>
                          {supplies
                            .slice(0, maxSupplies)
                            .map((supply, index) => (
                              <li
                                key={index}
                                className="flex items-center space-x-2 mb-3"
                              >
                                <FontAwesomeIcon
                                  icon={faSquareCheck}
                                  className="mr-2 text-blue-500"
                                />
                                <span>{supply.name}</span>
                              </li>
                            ))}
                          {supplies.length < maxSupplies &&
                            [...Array(maxSupplies - supplies.length)].map(
                              (_, index) => (
                                <li
                                  key={`empty-${index}`}
                                  className="flex items-center space-x-2 mb-2 invisible"
                                >
                                  <FontAwesomeIcon
                                    icon={faSquareCheck}
                                    className="text-gray-500"
                                  />
                                  <span>vide</span>
                                </li>
                              ),
                            )}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="px-2 flex justify-center">
                  <Link
                    to={`/classe/${classe.id}`}
                    onClick={() =>
                      addToDetailsOffers(classe, schoolClassesWithSupplies[0])
                    }
                  >
                    <button className="text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full">
                      Voir Plus
                    </button>
                  </Link>
                </div>
              </Card>
            </div>
          );
        })}
      </Swipper>
    </div>
  );
};

export default OffreClasses;
