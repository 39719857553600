import React from 'react';
import '../assets/css/header.css';
import logo from '../assets/images/logo.svg';
const Loader = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center z-10 _loader">
      <div className='absolute left-auto'>
        <img src={logo} alt="" />
      </div>
      <div className="flex-col gap-4 w-full flex items-center justify-center">
        <div className="w-44 h-44 p-10 border-8 text-blue-400 text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-blue-800 rounded-full"></div>
      </div>
    </div>
  );
};
export default Loader;
