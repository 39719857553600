import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Swipper = ({ children }) => {
  const carouselRef = useRef(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -carouselRef.current.clientWidth / 2,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.clientWidth / 2,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="relative">
      <div className="justify-end pb-4 space-x-2 hidden lg:flex">
        <button
          className="cursor-pointer flex py-2 justify-center items-center px-4 bg-black text-white rounded-sm"
          onClick={scrollLeft}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <button
          className="cursor-pointer flex justify-center items-center px-4 bg-black text-white rounded-sm"
          onClick={scrollRight}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

      <div ref={carouselRef} className="overflow-x-auto scrollbar-hidden">
        <div className="flex flex-nowrap">{children}</div>
      </div>
    </div>
  );
};

export default Swipper;
