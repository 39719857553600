import React from "react";

const Completed = () => {
  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6 text-center">
        <svg
          className="w-16 h-16 text-green-500 mx-auto mb-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 13l4 4L19 7"
          />
        </svg>
        <h2 className="text-2xl font-bold text-gray-800 mb-2">
          Paiement réussi
        </h2>
        <p className="text-gray-600 mb-4">
          Votre paiement a été complété avec succès. Merci pour votre achat !
        </p>
        <a
          href="/"
          className="inline-block bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
        >
          Retour à la page d'accueil
        </a>
      </div>
    </div>
  );
};

export default Completed;
