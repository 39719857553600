import React, { useEffect, useState, useCallback } from "react";
import Header from "../common/Header";
import apiService from "../services/api";
import { DAKAR, ERROR_RECUP } from "../constant/_const";
import Footer from "../common/Footer";
import PaymentUtils from "../modules/Payment/PaymentUtils";
import CouponUtils from "../modules/Coupon/CouponUtils";
import { manageLocalStorage, toastAlert } from "../func/_func";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faShoppingBag } from '@fortawesome/free-solid-svg-icons'; 
import { faSchool } from '@fortawesome/free-solid-svg-icons';
const Panier = () => {
  const [numberOfStudents, setNumberOfStudents] = useState(1);
  const [dakarDistrict, setDakarDistrict] = useState([]);
  const [classSupplies, setClassSupplies] = useState({});
  const icon = faClipboardList;
  const fetchClassSupplies = useCallback(() => {
    const storedClassSupplies = JSON.parse(localStorage.getItem("classSupplies")) || {};
    const formattedSupplies = formatSupplies(storedClassSupplies);

    /* Filtrer les classes sans fournitures */
    const filteredSupplies = Object.entries(formattedSupplies).reduce((acc, [key, value]) => {
      if (Array.isArray(value.supplies) && value.supplies.length > 0) {
        acc[key] = value;
      }
      return acc;
    }, {});

    setClassSupplies(filteredSupplies);
  }, []);

  const formatSupplies = (storedClassSupplies) => {
    return Object.entries(storedClassSupplies).reduce((acc, [key, supplies]) => {
      const [school, className] = key.split("-");
      acc[key] = { school, className, supplies: [] };

      if (Array.isArray(supplies)) {
        supplies.forEach(supply => {
          acc[key].supplies.push({
            id: supply.id,
            name: supply.name,
            quantity: supply.quantity,
            price: supply.price,
            provided_by_school: supply.provided_by_school,
            supply_type: supply.supply_type || "accessoires"
          });
        });
      } else {
        console.warn(`Fournitures non trouvées ou non formatées pour la clé ${key}`);
      }
      return acc;
    }, {});

  };

  const removeSupply = (id, classKey) => {
    setClassSupplies((prevClassSupplies) => {
      const updatedSupplies = { ...prevClassSupplies };

      // Vérifiez si la classe existe
      if (updatedSupplies[classKey]) {
        const filteredSupplies = updatedSupplies[classKey].supplies.filter(
          (item) => item.id.toString() !== id.toString()
        );
        updatedSupplies[classKey].supplies = filteredSupplies;

        // Supprimer la classe si elle n'a plus de fournitures
        if (updatedSupplies[classKey].supplies.length === 0) {
          delete updatedSupplies[classKey];
        }
      }

      // Sauvegarder uniquement les fournitures dans localStorage
      const classSuppliesToSave = Object.entries(updatedSupplies).reduce(
        (acc, [key, value]) => {
          acc[key] = value.supplies;
          return acc;
        },
        {}
      );

      localStorage.setItem("classSupplies", JSON.stringify(classSuppliesToSave));

      return updatedSupplies;
    });

    toastAlert("success", "Fourniture supprimée avec succès");
  };


  const fetchDakarDistrict = useCallback(async () => {
    try {
      const response = await apiService.get(DAKAR);
      setDakarDistrict(response);
    } catch (error) {
      console.error(ERROR_RECUP, error);
    }
  }, []);

  const loadNumberOfStudents = useCallback(() => {
    const savedNumberOfStudents = localStorage.getItem("numberOfStudents");
    if (savedNumberOfStudents) {
      setNumberOfStudents(Number(savedNumberOfStudents));
    }
  }, []);

  useEffect(() => {
    fetchClassSupplies();
    fetchDakarDistrict();
    loadNumberOfStudents();
  }, [fetchClassSupplies, fetchDakarDistrict, loadNumberOfStudents]);

  const incrementStudents = () => setNumberOfStudents((prev) => prev + 1);
  const decrementStudents = () =>
    setNumberOfStudents((prev) => Math.max(1, prev - 1));

  const incrementQuantity = (id, classKey) => {
    setClassSupplies((prevClassSupplies) => {
      const updatedSupplies = { ...prevClassSupplies };

      if (updatedSupplies[classKey]) {
        const updatedClassSupplies = {
          ...updatedSupplies,
          [classKey]: {
            ...updatedSupplies[classKey],
            supplies: updatedSupplies[classKey].supplies.map(item =>
              item.id.toString() === id.toString()
                ? { ...item, quantity: (item.quantity || 0) + 1 }
                : item
            ),
          },
        };

        // Sauvegarder uniquement les fournitures dans localStorage
        const classSuppliesToSave = Object.entries(updatedClassSupplies).reduce(
          (acc, [key, value]) => {
            acc[key] = value.supplies;
            return acc;
          },
          {}
        );

        localStorage.setItem("classSupplies", JSON.stringify(classSuppliesToSave));
        return updatedClassSupplies;
      }

      return updatedSupplies;
    });
  };

  const decrementQuantity = (id, classKey) => {
    setClassSupplies((prevClassSupplies) => {
      const updatedSupplies = { ...prevClassSupplies };

      if (updatedSupplies[classKey]) {
        const updatedClassSupplies = {
          ...updatedSupplies,
          [classKey]: {
            ...updatedSupplies[classKey],
            supplies: updatedSupplies[classKey].supplies.map(item =>
              item.id.toString() === id.toString()
                ? { ...item, quantity: Math.max((item.quantity || 0) - 1, 0) }
                : item
            ),
          },
        };

        // Sauvegarder uniquement les fournitures dans localStorage
        const classSuppliesToSave = Object.entries(updatedClassSupplies).reduce(
          (acc, [key, value]) => {
            acc[key] = value.supplies;
            return acc;
          },
          {}
        );

        localStorage.setItem("classSupplies", JSON.stringify(classSuppliesToSave));
        return updatedClassSupplies;
      }

      return updatedSupplies;
    });
  };


  const calculateTotal = () => {
    let total = 0;

    Object.entries(classSupplies).forEach(([classKey, { supplies }]) => {
      supplies.forEach(item => {
        total += (item.price || 0) * (item.quantity || 0);
      });
    });

    total *= numberOfStudents;

    localStorage.setItem("totalPrice", total);

    return total;
  };

  const currentClassSupplies = Object.entries(classSupplies).map(([key, { school, className, supplies }]) => {
    const total = calculateTotal(supplies).toFixed(2);

    const order = {
      schoolName: school,
      className: className,
      total: total,
      number_of_students: numberOfStudents,
    };

    const orderItem = {
      supplies: supplies.map(item => ({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        price: item.price,
      })),
    };

    return { order, orderItem, total };
  });
  const classSuppliesLength = Object.keys(classSupplies).length;
  localStorage.setItem("classSuppliesLength", classSuppliesLength);

  const ViderLePanier = () => {
    if (classSuppliesLength !== 0) {
      manageLocalStorage('remove', 'classSupplies');
      window.location.reload();
    }
  }

  return (
    <>
      <Header />
      <br />
      
      <section className="bg-white antialiased dark:bg-gray-900 pb-20">
        <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
          <div className=" dark:bg-gray-800 p-4 rounded-md border border-gray-300 dark:border-gray-700 shadow-lg">
            {Object.entries(classSupplies).length > 0 ? (
              Object.entries(classSupplies).map(([key, { school, className, supplies }]) => {
                const classKey = key;
                return (
                  <div key={key} className="mb-8 p-4 dark:bg-gray-800 rounded-lg ">
                    <h4 className="flex items-center text-xl font-bold text-gray-900 dark:text-white border-b-2 border-gray-300 dark:border-gray-700 pb-2 mb-4">
                      <FontAwesomeIcon icon={faSchool} className="text-blue-500 mr-2" />
                      <span>
                        {school} - {className}
                      </span>
                    </h4>

                    {/* Groupement par type de fournitures fournies par l'école */}
                    {supplies.filter(item => item.provided_by_school).length > 0 && (
                      <>
                        {/* Regrouper par type de fourniture */}
                        {Object.entries(supplies.reduce((acc, item) => {
                          if (item.provided_by_school) {
                            const type = item.supply_type;
                            if (!acc[type]) {
                              acc[type] = [];
                            }
                            acc[type].push(item);
                          }
                          return acc;
                        }, {})).map(([type, items]) => (
                          <div key={type} className="mb-4">
                            <div className="flex items-center bg-gray-700 p-3 rounded-md shadow-md mb-3 w-52 max-w-xs">
                              <FontAwesomeIcon icon={icon} className="text-white mr-2" />
                              <span className="text-white font-semibold text-lg">{type}</span>
                            </div>
                            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                              {items.map(item => (
                                <div
                                  key={item.id}
                                  className="bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-200 ease-in-out p-6 flex flex-col justify-between"
                                >
                                  <h5 className="font-semibold text-lg text-gray-900 dark:text-white mb-2">{item.name}</h5>
                                  <p className="text-md text-gray-700 dark:text-gray-300 mb-4">
                                    Prix: <span className="font-semibold">{Math.floor(parseFloat(item.price))} Fcfa</span>

                                  </p>
                                  <div className="flex justify-between">
                                    <div className="flex items-center gap-4">
                                      <button
                                        onClick={() => decrementQuantity(item.id, classKey)}
                                        className="group rounded-[50px] border border-gray-200 shadow-sm shadow-transparent p-2.5 flex items-center justify-center bg-white transition-all duration-500 hover:shadow-gray-200 hover:bg-gray-50 hover:border-gray-300 focus-within:outline-gray-300"
                                      >
                                        <svg
                                          className="stroke-gray-900 transition-all duration-500 group-hover:stroke-black"
                                          width={18}
                                          height={19}
                                          viewBox="0 0 18 19"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M4.5 9.5H13.5"
                                            stroke="currentColor"
                                            strokeWidth="1.6"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                      <input
                                        id={`counter-input-${item.id}`}
                                        value={item.quantity}
                                        readOnly
                                        type="text"
                                        className="border w-14 border-gray-200 rounded-full text-center"
                                      />

                                      <button
                                        onClick={() => incrementQuantity(item.id, classKey)}
                                        className="group rounded-[50px] border border-gray-200 shadow-sm shadow-transparent p-2.5 flex items-center justify-center bg-white transition-all duration-500 hover:shadow-gray-200 hover:bg-gray-50 hover:border-gray-300 focus-within:outline-gray-300"
                                      >
                                        <svg
                                          className="stroke-gray-900 transition-all duration-500 group-hover:stroke-black"
                                          width={18}
                                          height={19}
                                          viewBox="0 0 18 19"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M3.75 9.5H14.25M9 14.75V4.25"
                                            stroke="currentColor"
                                            strokeWidth="1.6"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </div>

                                    <div>
                                      <button
                                        onClick={() => removeSupply(item.id, key)}
                                        className="rounded-full group flex items-center justify-center focus-within:outline-red-500"
                                      >
                                        <svg
                                          width={34}
                                          height={34}
                                          viewBox="0 0 34 34"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            className="fill-red-50 transition-all duration-500 group-hover:fill-red-400"
                                            cx={17}
                                            cy={17}
                                            r={17}
                                            fill="currentColor"
                                          />
                                          <path
                                            className="stroke-red-500 transition-all duration-500 group-hover:stroke-white"
                                            d="M14.1673 13.5997V12.5923C14.1673 11.8968 14.7311 11.333 15.4266 11.333H18.5747C19.2702 11.333 19.834 11.8968 19.834 12.5923V13.5997M19.834 13.5997C19.834 13.5997 14.6534 13.5997 11.334 13.5997C6.90804 13.5998 27.0933 13.5998 22.6673 13.5997C21.5608 13.5997 19.834 13.5997 19.834 13.5997ZM12.4673 13.5997H21.534V18.8886C21.534 20.6695 21.534 21.5599 20.9807 22.1131C20.4275 22.6664 19.5371 22.6664 17.7562 22.6664H16.2451C14.4642 22.6664 13.5738 22.6664 13.0206 22.1131C12.4673 21.5599 12.4673 20.6695 12.4673 18.8886V13.5997Z"
                                            stroke="#EF4444"
                                            strokeWidth="1.6"
                                            strokeLinecap="round"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                    <div>
                                    </div>
                                  </div>
                                </div>
                              ))}

                            </div>
                          </div>
                        ))}
                      </>
                    )}
                    {/* Accessoires */}
                    {supplies.filter(item => !item.provided_by_school).length > 0 && (
                      <>
                        <div className="flex items-center bg-gray-700 p-3 rounded-md shadow-md mb-4 w-44 max-w-xs">
                          <FontAwesomeIcon icon={faShoppingBag} className="text-white mr-2" />
                          <span className="text-white font-semibold text-lg">Accessoires</span>
                        </div>
                        <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                          {supplies.filter(item => !item.provided_by_school).map(item => (
                            <div
                              key={item.id}
                              className="bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-200 ease-in-out p-6 flex flex-col justify-between"
                            >
                              <h5 className="font-semibold text-lg text-gray-900 dark:text-white mb-2">{item.name}</h5>
                              <p className="text-md text-gray-700 dark:text-gray-300 mb-4">
                                Prix: <span className="font-semibold">{parseFloat(item.price).toFixed(2)} Fcfa</span>
                              </p>
                              <div className="flex justify-between">
                                <div className="flex items-center gap-4">
                                  <button
                                    onClick={() => decrementQuantity(item.id, classKey)}
                                    className="group rounded-[50px] border border-gray-200 shadow-sm shadow-transparent p-2.5 flex items-center justify-center bg-white transition-all duration-500 hover:shadow-gray-200 hover:bg-gray-50 hover:border-gray-300 focus-within:outline-gray-300"
                                  >
                                    <svg
                                      className="stroke-gray-900 transition-all duration-500 group-hover:stroke-black"
                                      width={18}
                                      height={19}
                                      viewBox="0 0 18 19"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4.5 9.5H13.5"
                                        stroke="currentColor"
                                        strokeWidth="1.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                  <input
                                    id={`counter-input-${item.id}`}
                                    value={item.quantity || 0}
                                    readOnly
                                    type="text"
                                    className="border w-14 border-gray-200 rounded-full text-center"
                                  />
                                  <button
                                    onClick={() => incrementQuantity(item.id, classKey)}
                                    className="group rounded-[50px] border border-gray-200 shadow-sm shadow-transparent p-2.5 flex items-center justify-center bg-white transition-all duration-500 hover:shadow-gray-200 hover:bg-gray-50 hover:border-gray-300 focus-within:outline-gray-300"
                                  >
                                    <svg
                                      className="stroke-gray-900 transition-all duration-500 group-hover:stroke-black"
                                      width={18}
                                      height={19}
                                      viewBox="0 0 18 19"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3.75 9.5H14.25M9 14.75V4.25"
                                        stroke="currentColor"
                                        strokeWidth="1.6"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}

                        </div>
                      </>
                    )}
                  </div>
                );
              })

            ) : (
              (localStorage.removeItem("classSuppliesLength"),
                <p className="text-gray-600 dark:text-gray-300">Aucune fourniture trouvée.</p>)
            )}
            
          </div>
        </div>
      </section>
      
      <div className="fixed bottom-0 right-0 pb-5 pr-4 bg-white shadow-lg rounded-lg sm:w-full lg:w-full lg:max-w-md border border-gray-300 dark:bg-gray-800 dark:border-gray-600">
        <div className="flex flex-col px-4 py-3 space-y-4">
          {/* <!-- Nombre d'élèves --> */}
          <div className="flex items-center justify-between">
            <span className="text-xl font-semibold text-gray-900 dark:text-gray-100">
              Nombre d'élèves &nbsp;
            </span>
            <div className="flex items-center space-x-2">
              <button
                type="button"
                className="flex-shrink-0 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600 rounded-full h-10 w-10 flex items-center justify-center focus:outline-none"
                onClick={decrementStudents}
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 2"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1 1h16"
                  />
                </svg>
              </button>
              <input
                type="text"
                className="text-gray-900 dark:text-gray-100 border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 rounded-md text-center text-lg font-semibold w-20 focus:outline-none"
                readOnly
                value={numberOfStudents}
              />
              <button
                type="button"
                className="flex-shrink-0 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600 rounded-full h-10 w-10 flex items-center justify-center focus:outline-none"
                onClick={incrementStudents}
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* <!-- Total --> */}
          <div className="flex justify-between items-center">
            <span className="text-lg font-semibold text-gray-900 dark:text-gray-100">
              Total :&nbsp;
              <span className="text-red-600 font-bold">
                {Math.floor(calculateTotal())} FCFA
              </span>

            </span>
            <div>
              <button
                id="viderlepanier"
                onClick={ViderLePanier}
                disabled={classSuppliesLength === 0}
                className={`z-[1] 
 py-2 px-4 rounded ${classSuppliesLength === 0 ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-500 text-white cursor-pointer'
                  }`}
              >
                {classSuppliesLength === 0 ? 'Panier Vide' : 'Vider le panier'}
              </button>
            </div>
          </div>
          {/* <!-- Payment Button --> */}
          <div className="flex justify-between">
            {currentClassSupplies.length > 0 && (
              <PaymentUtils
                dakarDistrict={dakarDistrict}
                order={currentClassSupplies.map(item => item.order)}
                orderItem={currentClassSupplies.map(item => item.orderItem)}
              />
            )}
            <CouponUtils dakarDistrict={dakarDistrict} />

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Panier;
