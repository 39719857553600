/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Loader from "../../common/Loader";
import Swal from "sweetalert2";
import apiService from "../../services/api";
import { CREER_PAIEMENT, ERROR_RECUP, REGION, REGION_PRICE } from "../../constant/_const";
import { initFlowbite } from "flowbite";
import { manageLocalStorage, toastAlert } from "../../func/_func";
import { useTrackInputChange } from "../../services/tracking";
export function PaymentUtils({ dakarDistrict, order, orderItem }) {
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [region, setRegion] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [deliveryData, setDeliveryData] = useState([]);
  const [regionPrice, setRegionPrice] = useState([]);
  const [deliveryPaymentMethod, setDeliveryPaymentMethod] = useState("en_ligne");
  const [districtPrice, setDistrictPrice] = useState("");
  const [livraisonPrice, setLivraisonPrice] = useState("");
  const { trackInputChange } = useTrackInputChange();

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await apiService.get(REGION);
        setDeliveryData(response);
      } catch (error) {
        console.error(ERROR_RECUP);
      }
    };

    const fetchRegionPrice = async () => {
      try {
        const response = await apiService.get(REGION_PRICE);
        setRegionPrice(response);
      } catch (error) {
        console.error(ERROR_RECUP);
      }
    };
    fetchRegions();
    fetchRegionPrice();
    initFlowbite()
  }, []);
  trackInputChange('full_name', fullName);
  const ShowAlertModePaiement = () => {
    switch (deliveryPaymentMethod) {
      case 'en_ligne':
        toastAlert("success", "Vous avez choisi l'option payer à la livraison")
        break;
      case 'apres_livraison':
        toastAlert("success", "Vous avez choisi l'option payer en ligne")
        break;
      default:
        break;
    }
  }

  /* Événement lors du changement de région */
  const handleRegionChange = (e) => {
    const newRegion = e.target.value;
    setRegion(newRegion);
    setSelectedDistrict("");
    setDistrictPrice("");
    setDeliveryPaymentMethod("en_ligne");

    const regionData = regionPrice.find(r => r.regionns.name === newRegion);

    if (regionData) {
      setLivraisonPrice(regionData.price);
    } else {
      setLivraisonPrice("");
    }
    trackInputChange(newRegion);
  };

  /* Événement lors du changement de district */
  const handleDistrictChange = (e) => {
    const selected = e.target.value;
    setSelectedDistrict(selected);

    const districtData = dakarDistrict.find(district => district.district === selected);

    if (districtData) {
      setDistrictPrice(districtData.price);
      setLivraisonPrice(districtData.price);
    } else {
      setDistrictPrice("");
      setLivraisonPrice("");
    }
    trackInputChange(selected);

  };
  const paymentTotal = manageLocalStorage('get', 'totalPrice');

  const calculateTotal = () => {
    const livraisonCost = parseFloat(livraisonPrice) || 0;
    const totalPayment = parseFloat(paymentTotal) || 0;
    return totalPayment + livraisonCost;
  };

  /* desactiver button passer a la caisse */
  const isButtonDisabled = calculateTotal() <= 0;

  /* validation formulaire */
  const validateForm = () => {
    if (!fullName || !phoneNumber || !region) {
      Swal.fire("Erreur", "Tous les champs sont requis.", "error");
      return false;
    }

    /* Si la région est "Dakar", le district devient obligatoire */
    if (region === "Dakar" && !selectedDistrict) {
      Swal.fire("Erreur", "Veuillez sélectionner un district.", "error");
      return false;
    }

    const phoneRegex = /^[0-9]{9}$/;
    if (!phoneRegex.test(phoneNumber)) {
      Swal.fire(
        "Erreur",
        "Le numéro de téléphone doit comporter exactement 9 chiffres.",
        "error"
      );
      return false;
    }

    /* Validation du montant minimum */
    if (deliveryPaymentMethod === "en_ligne" && calculateTotal() <= 0) {
      Swal.fire(
        "Erreur",
        "Le montant total doit être supérieur à zéro pour un paiement en ligne.",
        "error"
      );
      return false;
    }

    if (deliveryPaymentMethod === "apres_livraison" && !livraisonPrice) {
      Swal.fire(
        "Erreur",
        "Les frais de livraison doivent être définis pour le paiement à la livraison.",
        "error"
      );
      return false;
    }

    return true;
  };

  /* Paiement processing */
  const ConfirmerPaiement = async () => {

    if (!validateForm()) {
      return;
    }

    Swal.fire({
      title: "Vous confirmez ?",
      text: "Êtes-vous sûr de vouloir confirmer le paiement ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, confirmer !",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const paymentData = {
          fullName,
          phoneNumber,
          region,
          total: deliveryPaymentMethod === 'apres_livraison'
            ? parseFloat(districtPrice || 0)
            : parseFloat(calculateTotal()),
          order: order,
          orderItem: orderItem,
          selectedDistrict: selectedDistrict,
          livraisonPrice
        };

        let timerInterval;
        Swal.fire({
          title: "Traitement en cours",
          html: "Redirection dans <b></b> secondes.",
          timer: 10000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              const remainingTime = Swal.getTimerLeft() / 1000;
              b.textContent = Math.ceil(remainingTime);
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
        try {
          const response = await apiService.post(CREER_PAIEMENT, {
            fullName,
            phoneNumber,
            region,
            total: paymentData.total,
            order: paymentData.order,
            orderItem: paymentData.orderItem,
            deliveryPaymentMethod,
            selectedDistrict,
            livraisonPrice
          });

          if (response.payment_url) {
            Swal.close();
            window.location.href = response.payment_url;
            manageLocalStorage('remove', ['classSuppliesLength', 'classSupplies', 'cart', 'cartLength']);
          }
          if (response.fail_paiement) {
            Swal.close();
            Swal.fire(
              "Erreur",
              "Une erreur est survenue lors du traitement du paiement, le total calculé est incorrect !",
              "error"
            );
          }
        } catch (error) {
          console.error("Erreur lors du traitement du paiement :", error);
          Swal.fire(
            "Erreur",
            "Une erreur est survenue lors du traitement du paiement.",
            "error"
          );
        }
      }
    });
  };

  if (loading) return <Loader />;

  return (
    <>
      <button
        data-modal-target="paimentprocessing"
        data-modal-toggle="paimentprocessing"
        className={`px-4 py-2 rounded-md font-semibold text-white transition-colors duration-300 ${isButtonDisabled
          ? "bg-gray-400 cursor-not-allowed"
          : "bg-blue-600 hover:bg-blue-700"
          }`}
        type="button"
        id="btnToDesabled"
      // disabled={isButtonDisabled}
      >
        Passer à la caisse
      </button>

      <div
        id="paimentprocessing"
        tabIndex={-1}
        className=" myPaymentUtils fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full max-h-full"
        style={{ marginLeft: "0rem" }}

      >
        <div className="relative w-full max-w-4xl max-h-full animate__animated animate__fadeInDown">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <form className="pb-4 space-y-2">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-green-800 dark:text-white">
                  FINALISER LE PAIEMENT
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="paimentprocessing"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5 space-y-4">
                <section className="bg-white dark:bg-gray-900 ">
                  <div className=" px-4 mx-auto max-w-screen-md space-y-5">
                    <div>
                      <label htmlFor="regions">Choisissez votre région</label>
                      <select
                        id="regions"
                        value={region}
                        onChange={handleRegionChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        <option value="" disabled>Sélectionnez une région</option>
                        <option value="Dakar">Dakar</option>
                        {deliveryData.map((region) => (
                          <option key={region.id} value={region.name}>
                            {region.name}
                          </option>
                        ))}
                      </select>
                      {region === "Dakar" && (
                        <div className="animate__animated animate__fadeInDown mt-4">
                          <label htmlFor="districts" className="block mb-2 text-sm font-medium text-gray-900">
                            Choisissez votre district
                          </label>
                          <select
                            id="districts"
                            required
                            value={selectedDistrict}
                            onChange={handleDistrictChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          >
                            <option value="" disabled>Sélectionnez un district</option>
                            {dakarDistrict.map((district, index) => (
                              <option key={index} value={district.district}>
                                {district.district}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="fullName"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Nom complet
                      </label>
                      <input
                        type="text"
                        id="fullName"
                        className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                        placeholder="Votre nom complet"
                        required=""
                        onChange={(e) => {
                          setFullName(e.target.value);
                          trackInputChange('full_name', e.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="phoneNumber"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Numéro de téléphone
                      </label>
                      <input
                        type="number"
                        id="phoneNumber"
                        className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                        placeholder="Votre numéro de téléphone"
                        required=""
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </div>
                </section>
              </div>
              {/* Masquer ou afficher les options de mode de paiement selon la région sélectionnée */}
              {region === "Dakar" && (
                <div className="space-y-4 pb-4 ml-4 lg:flex lg:space-x-6 lg:items-center">
                  <label className="lg:mt-4 block text-base font-bold text-gray-900 dark:text-gray-300">
                    Modes de paiement
                  </label>
                  <div className="flex items-center">
                    <input
                      id="paiement_en_ligne"
                      type="radio"
                      value="en_ligne"
                      name="payment_method"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={() => { setDeliveryPaymentMethod("en_ligne"); ShowAlertModePaiement(); }}
                      checked={deliveryPaymentMethod === "en_ligne"}
                    />
                    <label
                      htmlFor="paiement_en_ligne"
                      className="ml-2 text-base font-bold text-gray-900 dark:text-gray-300"
                    >
                      Payer en ligne
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="paiement_apres_livraison"
                      type="radio"
                      value="apres_livraison"
                      name="payment_method"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={() => { setDeliveryPaymentMethod("apres_livraison"); ShowAlertModePaiement(); }}
                    />
                    <label
                      htmlFor="paiement_apres_livraison"
                      className="ml-2 text-base font-bold text-gray-900 dark:text-gray-300"
                    >
                      Payer à la livraison
                    </label>
                  </div>
                </div>
              )}
              <div className="grid lg:flex lg:justify-between lg:items-center grid-cols-1 space-y-5 lg:space-y-0 ">
                <div className="pl-3">
                  Total des articles :&nbsp;
                  {paymentTotal} Fcfa
                </div>
                <div className="pl-3">
                  Livraison:&nbsp;
                  {livraisonPrice && parseFloat(livraisonPrice) > 0 ? (
                    `${Math.floor(parseFloat(livraisonPrice))} Fcfa`
                  ) : (
                    <span className="text-red-500">Non disponible</span>
                  )}

                </div>

                <div className="pl-3">
                  <span className="text-red-700 font-bold">
                    Total net à payer:
                  </span>{" "}
                  &nbsp;
                  <span className="animate__animated animate__flash font-bold text-red-600 bg-gray-200 px-1 py-1 rounded-sm">
                    {deliveryPaymentMethod === 'apres_livraison'
                      ? `${Math.floor(parseFloat(districtPrice || 0))} Fcfa`
                      : `${Math.floor(calculateTotal())} Fcfa`}
                  </span>

                  {/* <div className="loader">
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#1C64F2"
                      />
                    </svg>
                  </div> */}
                </div>

                <div className="pl-3 flex pr-3">
                  <button
                    onClick={ConfirmerPaiement}
                    type="button"
                    className="bg-green-600 py-2 px-2 rounded-xl border-2 text-white font-semibold text-md"
                  >
                    Terminer
                  </button>
                  <button
                    data-modal-hide="paimentprocessing"
                    type="button"
                    className="bg-red-600 py-2 px-2 rounded-xl border-2 text-white font-semibold text-md"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentUtils;