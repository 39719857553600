import React, { useEffect, useState, useCallback } from "react";
import Header from "../common/Header";
import apiService from "../services/api";
import { DAKAR, ERROR_RECUP } from "../constant/_const";
import Footer from "../common/Footer";
import PaymentUtils from "../modules/Payment/PaymentUtils";
import CouponUtils from "../modules/Coupon/CouponUtils";
import { manageLocalStorage, toastAlert } from "../func/_func";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { faSchool } from '@fortawesome/free-solid-svg-icons';

const CartFreeBuying = () => {
const [numberOfStudents, setNumberOfStudents] = useState(1);
const [dakarDistrict, setDakarDistrict] = useState([]);
const [classSupplies, setClassSupplies] = useState({});
const icon = faClipboardList;

const fetchClassSupplies = useCallback(() => {
const storedClassSupplies = JSON.parse(localStorage.getItem("classSupplies")) || {};
const formattedSupplies = formatSupplies(storedClassSupplies);

/* Filtrer les classes sans fournitures */
const filteredSupplies = Object.entries(formattedSupplies).reduce((acc, [key, value]) => {
if (Array.isArray(value.supplies) && value.supplies.length > 0) {
acc[key] = value;
}
return acc;
}, {});

setClassSupplies(filteredSupplies);
}, []);

const formatSupplies = (storedClassSupplies) => {
return Object.entries(storedClassSupplies).reduce((acc, [key, supplies]) => {
const [school, className] = key.split("-");
acc[key] = { school, className, supplies: [] };

if (Array.isArray(supplies)) {
supplies.forEach(supply => {
acc[key].supplies.push({
id: supply.id,
name: supply.name,
quantity: supply.quantity,
price: supply.price,
provided_by_school: supply.provided_by_school,
supply_type: supply.supply_type || "accessoires"
});
});
} else {
console.warn(`Fournitures non trouvées ou non formatées pour la clé ${key}`);
}
return acc;
}, {});

};

const removeSupply = (id, classKey) => {
setClassSupplies((prevClassSupplies) => {
const updatedSupplies = { ...prevClassSupplies };

// Vérifiez si la classe existe
if (updatedSupplies[classKey]) {
const filteredSupplies = updatedSupplies[classKey].supplies.filter(
(item) => item.id.toString() !== id.toString()
);
updatedSupplies[classKey].supplies = filteredSupplies;

// Supprimer la classe si elle n'a plus de fournitures
if (updatedSupplies[classKey].supplies.length === 0) {
delete updatedSupplies[classKey];
}
}

// Sauvegarder uniquement les fournitures dans localStorage
const classSuppliesToSave = Object.entries(updatedSupplies).reduce(
(acc, [key, value]) => {
acc[key] = value.supplies;
return acc;
},
{}
);

localStorage.setItem("classSupplies", JSON.stringify(classSuppliesToSave));

return updatedSupplies;
});

toastAlert("success", "Fourniture supprimée avec succès");
};


const fetchDakarDistrict = useCallback(async () => {
try {
const response = await apiService.get(DAKAR);
setDakarDistrict(response);
} catch (error) {
console.error(ERROR_RECUP, error);
}
}, []);

const loadNumberOfStudents = useCallback(() => {
const savedNumberOfStudents = localStorage.getItem("numberOfStudents");
if (savedNumberOfStudents) {
setNumberOfStudents(Number(savedNumberOfStudents));
}
}, []);

useEffect(() => {
fetchClassSupplies();
fetchDakarDistrict();
loadNumberOfStudents();
}, [fetchClassSupplies, fetchDakarDistrict, loadNumberOfStudents]);

const incrementStudents = () => setNumberOfStudents((prev) => prev + 1);
const decrementStudents = () =>
setNumberOfStudents((prev) => Math.max(1, prev - 1));

const incrementQuantity = (id, classKey) => {
setClassSupplies((prevClassSupplies) => {
const updatedSupplies = { ...prevClassSupplies };

if (updatedSupplies[classKey]) {
const updatedClassSupplies = {
...updatedSupplies,
[classKey]: {
...updatedSupplies[classKey],
supplies: updatedSupplies[classKey].supplies.map(item =>
item.id.toString() === id.toString()
? { ...item, quantity: (item.quantity || 0) + 1 }
: item
),
},
};

// Sauvegarder uniquement les fournitures dans localStorage
const classSuppliesToSave = Object.entries(updatedClassSupplies).reduce(
(acc, [key, value]) => {
acc[key] = value.supplies;
return acc;
},
{}
);

localStorage.setItem("classSupplies", JSON.stringify(classSuppliesToSave));
return updatedClassSupplies;
}

return updatedSupplies;
});
};

const decrementQuantity = (id, classKey) => {
setClassSupplies((prevClassSupplies) => {
const updatedSupplies = { ...prevClassSupplies };

if (updatedSupplies[classKey]) {
const updatedClassSupplies = {
...updatedSupplies,
[classKey]: {
...updatedSupplies[classKey],
supplies: updatedSupplies[classKey].supplies.map(item =>
item.id.toString() === id.toString()
? { ...item, quantity: Math.max((item.quantity || 0) - 1, 0) }
: item
),
},
};

// Sauvegarder uniquement les fournitures dans localStorage
const classSuppliesToSave = Object.entries(updatedClassSupplies).reduce(
(acc, [key, value]) => {
acc[key] = value.supplies;
return acc;
},
{}
);

localStorage.setItem("classSupplies", JSON.stringify(classSuppliesToSave));
return updatedClassSupplies;
}

return updatedSupplies;
});
};


const calculateTotal = () => {
let total = 0;

Object.entries(classSupplies).forEach(([classKey, { supplies }]) => {
supplies.forEach(item => {
total += (item.price || 0) * (item.quantity || 0);
});
});

total *= numberOfStudents;

localStorage.setItem("totalPrice", total);

return total;
};

const currentClassSupplies = Object.entries(classSupplies).map(([key, { school, className, supplies }]) => {
const total = calculateTotal(supplies).toFixed(2);

const order = {
schoolName: school,
className: className,
total: total,
number_of_students: numberOfStudents,
};

const orderItem = {
supplies: supplies.map(item => ({
id: item.id,
name: item.name,
quantity: item.quantity,
price: item.price,
})),
};

return { order, orderItem, total };
});
const classSuppliesLength = Object.keys(classSupplies).length;
localStorage.setItem("classSuppliesLength", classSuppliesLength);

const ViderLePanier = () => {
if (classSuppliesLength !== 0) {
manageLocalStorage('remove', 'classSupplies');
window.location.reload();
}
}

return (
<>
    <Header />
    <br />

    <section className="bg-white antialiased dark:bg-gray-900 pb-20">
        <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
            <div className="dark:bg-gray-800 p-4 rounded-md border border-gray-300 dark:border-gray-700 shadow-lg">
                {/* Vérifier si le panier contient des fournitures */}
                {localStorage.getItem("cart") ? (
                // Si le cart existe
                (() => {
                const cartSupplies = JSON.parse(localStorage.getItem("cart"));
                return (
                <div className="mb-8 p-4 dark:bg-gray-800 rounded-lg">
                    <h4
                        className="flex items-center text-xl font-bold text-gray-900 dark:text-white border-b-2 border-gray-300 dark:border-gray-700 pb-2 mb-4">
                        Fournitures dans le Panier
                    </h4>
                    <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        {Object.entries(cartSupplies).map(([type, items]) => (
                        items.map(item => (
                        <div key={item.id}
                            className="bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-200 ease-in-out p-6 flex flex-col justify-between">
                            <h5 className="font-semibold text-lg text-gray-900 dark:text-white mb-2">{item.name}</h5>
                            <p className="text-md text-gray-700 dark:text-gray-300 mb-4">Prix: <span
                                    className="font-semibold">{item.price} Fcfa</span></p>
                            <p className="text-md text-gray-700 dark:text-gray-300 mb-4">Quantité: <span
                                    className="font-semibold">{item.quantity}</span></p>
                        </div>
                        ))
                        ))}
                    </div>
                </div>
                );
                })()
                ) : (
                <p className="text-gray-600 dark:text-gray-300">Aucune fourniture dans le panier.</p>
                )}
            </div>
        </div>
    </section>
    <div
        className="fixed bottom-0 right-0 pb-5 pr-4 bg-white shadow-lg rounded-lg sm:w-full lg:w-full lg:max-w-md border border-gray-300 dark:bg-gray-800 dark:border-gray-600">
        <div className="flex flex-col px-4 py-3 space-y-4">
            {/* <!-- Nombre d'élèves --> */}
            <div className="flex items-center justify-between">
                <span className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                    Nombre d'élèves &nbsp;
                </span>
                <div className="flex items-center space-x-2">
                    <button type="button"
                        className="flex-shrink-0 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600 rounded-full h-10 w-10 flex items-center justify-center focus:outline-none"
                        onClick={decrementStudents}>
                        <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 18 2">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                d="M1 1h16" />
                        </svg>
                    </button>
                    <input type="text"
                        className="text-gray-900 dark:text-gray-100 border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 rounded-md text-center text-lg font-semibold w-20 focus:outline-none"
                        readOnly value={numberOfStudents} />
                    <button type="button"
                        className="flex-shrink-0 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600 rounded-full h-10 w-10 flex items-center justify-center focus:outline-none"
                        onClick={incrementStudents}>
                        <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                d="M9 1v16M1 9h16" />
                        </svg>
                    </button>
                </div>
            </div>

            {/* <!-- Total --> */}
            <div className="flex justify-between items-center">
                <span className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                    Total :&nbsp;
                    <span className="text-red-600 font-bold">
                        {Math.floor(calculateTotal())} FCFA
                    </span>

                </span>
                <div>
                    <button id="viderlepanier" onClick={ViderLePanier} disabled={classSuppliesLength===0}
                        className={`z-[1] py-2 px-4 rounded ${classSuppliesLength===0 ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-red-500 text-white cursor-pointer' }`}>
                        {classSuppliesLength === 0 ? 'Panier Vide' : 'Vider le panier'}
                    </button>
                </div>
            </div>
            {/* <!-- Payment Button --> */}
            <div className="flex justify-between">
                {currentClassSupplies.length > 0 && (
                <PaymentUtils dakarDistrict={dakarDistrict} order={currentClassSupplies.map(item=> item.order)}
                    orderItem={currentClassSupplies.map(item => item.orderItem)}
                    />
                    )}
                    <CouponUtils dakarDistrict={dakarDistrict} />

            </div>
        </div>
    </div>
    <Footer />
</>
);
};

export default CartFreeBuying;
