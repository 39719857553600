import React from "react";

const Faq = () => {
  return (
    <div className="bg-[#fdebd2] p-6 rounded-lg shadow-lg  ">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">
        Préparez la rentrée scolaire sans stress
      </h2>
      <p className="text-lg text-gray-700 leading-relaxed">
        Préparez la rentrée scolaire de vos enfants sans stress grâce à la Foire
        Digitale de la Rentrée Scolaire ! En collaboration avec{" "}
        <strong>MOUKAT Distribution</strong>, qui dispose de boutiques dans 7
        régions du Sénégal, et la startup innovante <strong>KATI360</strong>,
        nous vous proposons une solution simple et efficace pour acheter toutes
        les fournitures scolaires de vos enfants en un seul clic.
      </p>

      <p className="mt-4 text-lg text-gray-700 leading-relaxed">
        Nous savons qu'à chaque rentrée scolaire, de nombreux parents
        rencontrent des difficultés à trouver l'ensemble des fournitures
        scolaires nécessaires dans une seule boutique. Avec les exigences
        professionnelles et les déplacements compliqués à Dakar et ses environs,
        cette période peut rapidement devenir une source de stress.
      </p>

      <p className="mt-4 text-lg text-gray-700 leading-relaxed">
        <strong>Notre solution :</strong> la Foire Digitale de la Rentrée
        Scolaire. Achetez toutes vos fournitures scolaires en ligne et
        faites-vous livrer en moins de 48 heures ! Grâce à cette foire, vous
        bénéficiez d'une offre complète et diversifiée, accessible depuis le
        confort de votre domicile.
      </p>

      <p className="mt-4 text-lg text-gray-700 leading-relaxed">
        Plus besoin de parcourir plusieurs boutiques ou de faire face aux
        embouteillages – nous vous simplifions la vie. Ne manquez pas cette
        opportunité de préparer la rentrée scolaire de vos enfants en toute
        sérénité.
      </p>

      <p className="mt-4 text-lg text-gray-700 leading-relaxed">
        <strong>Commandez dès maintenant</strong> et recevez vos articles
        rapidement, où que vous soyez au Sénégal. Simple, rapide et efficace.
        Faites de cette rentrée scolaire une réussite avec la Foire Digitale de
        la Rentrée Scolaire, en partenariat avec Moukat Distribution et Kati360.
      </p>
    </div>  
  );
};

export default Faq;
