import React from "react";

function App() {
  return (
    <div className="container mx-auto p-4">
      {/* Header Section */}
      <header className="flex justify-between items-center py-4">
        <div className="text-4xl text-orange-500 font-bold">Etsy</div>
        <nav className="space-x-6">
          <a href="#" className="text-gray-700 hover:text-gray-900">Catégories</a>
          <a href="#" className="text-gray-700 hover:text-gray-900">Cadeaux</a>
          <a href="#" className="text-gray-700 hover:text-gray-900">Bons plans pré-fêtes</a>
          <a href="#" className="text-gray-700 hover:text-gray-900">Articles de déco</a>
          <a href="#" className="text-gray-700 hover:text-gray-900">Articles de mode</a>
          <a href="#" className="text-gray-700 hover:text-gray-900">Liste de cadeaux</a>
        </nav>
        <div className="flex space-x-4">
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Que cherchez-vous?"
              className="border border-gray-300 rounded-l px-4 py-2"
            />
            <button className="bg-orange-500 text-white px-4 py-2 rounded-r">
              🔍
            </button>
          </div>
          <a href="#" className="text-gray-700 hover:text-gray-900">Se connecter</a>
          <a href="#" className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300">Vendre avec Etsy</a>
        </div>
      </header>

      {/* Banner Section */}
      <section className="bg-orange-400 text-center py-16">
        <h1 className="text-3xl text-white font-semibold">Découvrez les dernières tendances dans de petites boutiques.</h1>
      </section>

      {/* Trending Items Section */}
      <section className="grid grid-cols-3 gap-8 py-10 text-center">
        <div className="item">
          <img
            src="link-to-bag-image"
            alt="Sacs à main en daim"
            className="w-24 h-24 mx-auto rounded-full"
          />
          <p className="mt-4">Sacs à main en daim</p>
        </div>
        <div className="item">
          <img
            src="link-to-pumpkin-image"
            alt="Épices d'automne"
            className="w-24 h-24 mx-auto rounded-full"
          />
          <p className="mt-4">Épices d'automne</p>
        </div>
        <div className="item">
          <img
            src="link-to-chair-image"
            alt="Touche de rouge"
            className="w-24 h-24 mx-auto rounded-full"
          />
          <p className="mt-4">Touche de rouge</p>
        </div>
        <div className="item">
          <img
            src="link-to-jeans-image"
            alt="Jean vintage"
            className="w-24 h-24 mx-auto rounded-full"
          />
          <p className="mt-4">Jean vintage</p>
        </div>
        <div className="item">
          <img
            src="link-to-marble-furniture-image"
            alt="Mobilier en marbre"
            className="w-24 h-24 mx-auto rounded-full"
          />
          <p className="mt-4">Mobilier en marbre</p>
        </div>
        <div className="item">
          <img
            src="link-to-librarian-image"
            alt="Articles pour bibliothécaires"
            className="w-24 h-24 mx-auto rounded-full"
          />
          <p className="mt-4">Articles pour bibliothécaires</p>
        </div>
      </section>
    </div>
  );
}

export default App;
