import { usePostHog } from "posthog-js/react";

export const useTrackClick = () => {
  const posthog = usePostHog();

  const trackClick = (eventName, userName) => {
    posthog.capture(eventName, {
      user_name: userName,
    });
  };

  return { trackClick };
};

export const useTrackPageView = () => {
  const posthog = usePostHog();

  const trackPageView = (pageName) => {
    posthog.capture("page_view", {
      page_name: pageName,
    });
  };

  return { trackPageView };
};

export const useTrackInputChange = () => {
  const posthog = usePostHog();

  const trackInputChange = (inputName, inputValue) => {
//     console.log("Tracking input change:", inputName, inputValue);
    posthog.capture("input_changed", {
      input_name: inputName,
      input_value: inputValue,
    });
  };

  return { trackInputChange };
};
