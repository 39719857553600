import React from 'react';
import { Carousel } from 'flowbite-react';
import { NEW_PATH, OLD_PATH } from '../constant/_const';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { posthog } from 'posthog-js';
import { useTrackClick } from '../services/tracking';

const School = ({ schools }) => {
  const { trackClick } = useTrackClick();

  const URL_MEDIA = process.env.REACT_APP_API_URL_MEDIA;

  if (schools.length === 0) {
    return (
      <div className="flex justify-center items-center min-w-20 mt-5 py-2">
        <Skeleton width={1200} height={400} />
      </div>
    );
  }

  return (
    <div className="mt-10 px-4 sm:px-6 lg:px-8">
      <div className="relative">
        <div className="h-48 sm:h-56 md:h-64 lg:h-80 xl:h-96">
          <Carousel>
            {schools.map((school, index) => {
              const imagePath = school.image.replace(OLD_PATH, NEW_PATH);
              const imageUrl = `${URL_MEDIA}/${imagePath}`;
              return (
                <div
                  key={index}
                  className="relative w-full h-full"
                  style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <div className="absolute inset-0 bg-black bg-opacity-25 flex items-center justify-center">
                    <div className="flex gap-4 lg:mt-44 justify-center items-center flex-col">
                      <h2 className="text-white text-xl">{school.name}</h2>
                      <Link to={`/school/${school.id}`}>
                        <button
                          onClick={() => {
                            trackClick(`A cliqué sur l'école ${school.name}`, "user_1");
                          }}
                          className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition">
                          Voire les offres de l'ecole
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default School;
