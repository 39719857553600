import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import apiService from '../services/api';
import {
  CLASSES_PAR_CATEGORIES,
  ERROR_RECUP,
  NEW_PATH,
  OLD_PATH,
} from '../constant/_const';
import Loader from '../common/Loader';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { Card } from 'flowbite-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import Swipper from '../components/_Swipper';

const OffresParEcoles = () => {
  const { id } = useParams();
  const [categoryDetails, setCategoryDetails] = useState({ data: [] });
  const [selectedClass, setSelectedClass] = useState('');
  const [loading, setLoading] = useState(true);
  const URL_MEDIA = process.env.REACT_APP_API_URL_MEDIA;

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      setLoading(true);
      try {
        const response = await apiService.get(
          `${CLASSES_PAR_CATEGORIES}?todo=getschooldetails&id=${id}`
        );
        setCategoryDetails(response);
      } catch (error) {
        console.error(ERROR_RECUP, error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryDetails();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  const groupSuppliesByType = (supplies) => {
    return supplies.reduce((acc, supply) => {
      if (!acc[supply.supply_type.name]) {
        acc[supply.supply_type.name] = [];
      }
      const supplyWithQuantity = {
        ...supply.supply,
        quantity: supply.quantity,
      };
      acc[supply.supply_type.name].push(supplyWithQuantity);
      return acc;
    }, {});
  };

  const filteredSchoolClasses = categoryDetails.school_classes.filter(
    (schoolClass) =>
      schoolClass.class_supplies.length > 0 &&
      (!selectedClass || schoolClass.class.id === parseInt(selectedClass, 10))
  );

  const schoolCategory =
    categoryDetails.school_classes.length > 0
      ? categoryDetails.school_classes[0].class.category.name
      : '';

  const schoolName = categoryDetails ? categoryDetails.name : '';

  const addToDetailsOffers = (classe, schoolClass) => {
    const data = {
      className: classe.name,
      classCategory: schoolCategory,
      school: schoolName,
      supplies: groupSuppliesByType(schoolClass.class_supplies),
      image: classe.image
        ? `${URL_MEDIA}/${classe.image.replace(OLD_PATH, NEW_PATH)}`
        : '',
    };
    localStorage.setItem('detailsOffers', JSON.stringify(data));
  };

  return (
    <>
      <Header />
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4"> Les offres de l'école</h1>
        <div className="lg:w-96 lg:px-5 flex flex-col sm:flex-row justify-start gap-4">
          <div className="w-full sm:w-1/2">
            <label htmlFor="class_select" className="sr-only">
              Choisir classe
            </label>
            <select
              id="class_select"
              className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              <option value="" disabled>
                Choisir classe
              </option>
              {categoryDetails.school_classes.map((schoolClass, index) => (
                <option key={index} value={schoolClass.class.id}>
                  {schoolClass.class.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <br />
        <div className="flex flex-col sm:flex-row sm:space-y-0 space-y-4 lg:mt-4">
          <div className="bg-gray-100 border border-gray-300 rounded-lg p-4">
            <p className="text-gray-700">
              <span className="font-semibold">École :</span> {schoolName}
            </p>
          </div>
        </div>
        <br />
        <Swipper>
          <div className="flex flex-row space-x-4 overflow-x-auto scrollbar-hidden">
            {filteredSchoolClasses.map((schoolClass, i) => (
              <div
                key={`${schoolClass.id}-${i}`}
                className="flex-shrink-0 flex flex-col items-center mb-6 w-full sm:w-80 md:w-96 lg:w-72 xl:w-80 2xl:w-96"
              >
                <Card
                  imgAlt={schoolClass.class.name}
                  imgSrc={`${URL_MEDIA}/${schoolClass.class.image.replace(
                    OLD_PATH,
                    NEW_PATH,
                  )}`}
                  className="rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {schoolClass.class.name}
                  </h5>
                  <div className="text-sm text-gray-600 dark:text-gray-400">
                    <div className="w-full">
                      {Object.keys(groupSuppliesByType(schoolClass.class_supplies))
                        .length > 0 ? (
                        Object.entries(
                          groupSuppliesByType(schoolClass.class_supplies),
                        ).map(([type, supplies]) => (
                          <div key={`${type}-${i}`}>
                            <h4 className="text-md font-semibold">{type}</h4>
                            <ul className="list-disc mt-2 space-y-2">
                              {supplies.slice(0, 5).map((supply, j) => (
                                <li
                                  key={`${supply.id}-${type}-${j}`}
                                  className="flex items-center"
                                >
                                  <FontAwesomeIcon
                                    icon={faSquareCheck}
                                    className="mr-2 text-blue-500"
                                  />
                                  <p>{supply.name}</p>
                                </li>
                              ))}
                              {supplies.length > 5 && <>. . . . .</>}
                            </ul>
                          </div>
                        ))
                      ) : (
                        <p className="mt-2 text-center">
                          Aucune fourniture trouvée pour cette classe.
                        </p>
                      )}
                    </div>
                  </div>
                  <Link
                    to={`/classe/${schoolClass.class.id}`}
                    className="flex justify-center text-center px-5"
                  >
                    <button
                      onClick={() =>
                        addToDetailsOffers(schoolClass.class, schoolClass)
                      }
                      className="text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full"
                    >
                      Voir Plus
                    </button>
                  </Link>
                </Card>
              </div>
            ))}
          </div>
        </Swipper>
      </div>
      <Footer />
    </>
  );
};

export default OffresParEcoles;
