import posthog from "posthog-js";

const initPostHog = () => {
  posthog.init("phc_7HMxyUhuwjpcuwkdGYrkq9zlIKP9t0sniFdxtHC3dU8", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "always",
  });
};

export default initPostHog;
